.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
}

.bgImage {
  background-image: url("./images/tannedwoman-removebg-preview.png");
  background-repeat: no-repeat;
  background-position: bottom right;
}
@media (max-width: 575px) {
  .bgImage {
    background-size: 85%;
    background-image: url("./images/tannedwoman-removebg-preview.png");
    background-repeat: no-repeat;
    background-position: 260% 104%;
  }
}
.ant-segmented-item {
  /* background: linear-gradient(to right, #ff6e7f, #bfe9ff); */
  /* border: none; */
  padding: 2px 12px;
  margin: 2px 2px;
  color: black;
}

.ant-segmented-item-selected {
  background: linear-gradient(to right, #56ab2f, #a8e063);
  /* border: none; */
  color: white !important;
}
