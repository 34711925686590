.card-image {
  object-fit: contain;
  height: 50px;
  width: 100%;
  border-radius: 10px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}
.cards-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.card-item {
  position: relative;
}

.card-item img {
  position: absolute;
  top: -15px;
  left: -15px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5);
}
