body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.gradient {
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(38, 38, 38, 1) 58%
  ) !important;
}
/* 
.headerUtente {
  position: relative;
  margin-top: 20px;
}
.headerUtente::before {
  content: "";
  display: block;
  position: absolute;
  top: 10;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    212,
    211,
    211,
    0.5
  ); 
}
.headerUtente img {
  display: block;
  max-width: 100%;
  height: auto;
} */
